import React from "react";
import Layout from "../../../components/layout";
import Hero from "../../../components/company/careers/hero";
import Opening from "../../../components/company/careers/openings";
import SecondaryFooter from "../../../components/layout/secondaryFooter";
import { graphql } from "gatsby";

export default function Careers(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicCareerPage.edges[0].node.dataRaw;

  return (
    <Layout>
      <Hero data={data} />
      <Opening data={data.body[0]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const career = graphql`
  query career {
    allPrismicCareerPage {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
