import React from "react";
import { Button } from "@react-md/button";
import { Link } from "gatsby";
import "./style.scss";
import { RichText } from "prismic-reactjs";
import { useLocation } from "@reach/router";

export default function Opening({ data }) {
  const location = useLocation();
  return (
    <section className="careers-openings">
      <div className="container">
        {data.items.map((content, index) => {
          const slug = (location.pathname + "/" + content.link.slug).replace("//", "/");
          return (
            <div key={index} className="wrapper">
              <div>
                <RichText render={content.position} />
                <RichText render={content.job_description} />
                <RichText render={content.requirements} />

              </div>
              <div>

                <Link to={slug}>
                  <Button themeType="contained" className="btn">
                    READ MORE
                  </Button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
