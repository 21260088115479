import React from "react";
import { Button } from "@react-md/button";
import { Link } from "gatsby";
import "./style.scss";

export default function SecondaryFooter() {
  return (
    <section className="secondaryFooter">
      <div className="container">
        <div className="dot footer-dot-one"></div>
        <h2>
          <span className="red-text">Interested</span> in our services?
        </h2>
        <p>
          Book <span>a free consultation session</span> with our senior experts for a deeper understanding of the benefits we can provide
        </p>
        <Link to="/company/contact" >
          <Button themeType="contained" className="btn" style={{ marginTop: 0 }}>
            CONTACT US
          </Button>
        </Link>
        <div className="dot footer-dot-two"></div>
      </div>
    </section>
  );
}
