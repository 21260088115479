import { RichText } from "prismic-reactjs";
import React from "react";
import "./style.scss";
export default function Hero({ data }) {
  return (
    <section className="career-hero">
      <div className="container">
        <RichText render={data.title} />
        <RichText render={data.description_one} style="margin-top: 1rem" />
        <RichText render={data.description_two} />
      </div>
    </section>
  );
}
